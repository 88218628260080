<template>
  <div class="code">
    <div class="code-head">
      <div class="code-detail" @click="$router.push('./codedetail')">
        {{$t('积分明细')}}
      </div>
      <img class="code-img" src="../assets/img/code.png" />
      <div class="code-title">{{$t('我的积分')}}</div>
      <div class="code-num">{{user.points}}</div>
    </div>
    <div>
      <div style="position: relative;width:fit-content;margin:0 auto">
        <van-button color="#5d74f2" class="code-btn" @click="show = true"
          >{{$t('充值')}}</van-button
        >
        <div class="code-recharge-record">{{$t('充值记录')}}</div>
      </div>
      <van-button
        color="#5d74f2"
        plain
        class="code-btn turn-btn"
        @click="turnShow = true"
        >{{$t('转让')}}</van-button
      >
    </div>

    <van-popup v-model="show">
      <div class="code-recharge">
        <div class="code-recharge-title">{{$t('充值积分')}}</div>
        <van-field v-model="email" :label="$t('邮箱')" :placeholder="$t('form.eamil')" />
        <van-field
          v-model="code"
          :label="$t('充值积分')"
          :placeholder="$t('充值积分')"
        />
        <van-field name="uploader" :label="$t('付款凭证')">
          <template #input>
            <van-uploader v-model="uploader" />
          </template>
        </van-field>
        <span></span>
      </div>
      <div class="code-recharge-grounp">
        <van-button color="#5d74f2" class="code-recharge-btn">{{$t('提交')}}</van-button>
        <van-button color="#888" class="code-recharge-btn" @click="show = false"
          >{{$t('取消')}}</van-button
        >
      </div>
    </van-popup>

    <van-popup v-model="turnShow">
      <div class="code-recharge-title">{{$t('积分转让')}}</div>
      <div class="code-recharge">
        <van-field
          v-model="turnEmail"
          :label="$t('转让邮箱')"
          :placeholder="$t('请输入转让邮箱地址')"
        />
        <van-field
          v-model="turnCode"
          :label="$t('转让积分')"
          :placeholder="$t('请输入转让积分数量')"
        />
        <span></span>
      </div>
      <div class="code-recharge-grounp">
        <van-button color="#5d74f2" class="code-recharge-btn">{{$t('提交')}}</van-button>
        <van-button
          color="#888"
          class="code-recharge-btn"
          @click="turnShow = false"
          >{{$t('取消')}}</van-button
        >
      </div>
    </van-popup>
  </div>
</template>
<script>
export default {
  name: "Code",
  data() {
    return {
      show: false,
      code: "",
      email: "", // 充值邮箱
      uploader: [], // 付款凭证
      turnShow: false, // 积分转让弹窗显示
      turnEmail: "", // 积分转让邮箱
      turnCode: "", // 积分转让数量
    };
  },
  mounted(){
    this.userCenter()
  },
  methods: {
    userCenter() { // 获取用户数据
      this.$http.post("/v1/userCenter").then((res) => {
        if (res.code == 200) {
          this.user = res.data.user;
        }
      });
    },
  },
};
</script>
<style scoped>
.code {
  padding: 2vh 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100vh;
}
.code-head {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.code-img {
  display: block;
  width: 50px;
  height: 50px;
}
.code-title {
  font-size: 15px;
  margin: 35px 0 10px;
}
.code-num {
  font-size: 40px;
  font-weight: bold;
  color: #555;
}
.code-btn {
  display: block;
  width: 180px;
  height: 35px;
  margin: 0 auto;
}
.turn-btn {
  margin-top: 20px;
}
.code-detail {
  position: absolute;
  top: 16px;
  right: 16px;
}

.code-recharge {
  width: 80vw;
}

.van-popup {
  border-radius: 5px;
}

.code-recharge-grounp {
  display: flex;
  justify-content: space-around;
  padding: 15px 0;
}

.code-recharge-btn {
  width: 80px;
  height: 30px;
}

.code-recharge-title {
  text-align: center;
  padding: 12px 16px;
  border-bottom: 1px solid #ddd;
  font-weight: bold;
}

.code-recharge-record{
  position:absolute;
  color: #5d74f2;
  font-size: 12px;
  right: -58px;
  top: 8px;
}
</style>
